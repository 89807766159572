.wext-button--cta-wrapper {
  background: rgba(9, 17, 53, 0.03);
  background-blend-mode: normal;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(9, 17, 53, 0.05);
  display: inline-block;
}
.hoverable--overlay {
  display: none !important;
}
.hoverable:hover .hoverable--overlay {
  display: flex !important;
}

/* React select */
.wext__placeholder {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.wext__multi-value__remove {
  border-radius: 0.2rem !important;
}
.wext__single-value {
  font-size: 0.875rem !important;
}

.wext__input-container {
  font-size: 0.875rem !important;
  margin: 1px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.wext__control.wext__control--is-focused {
  border: 2px solid #37c5f7 !important;
  appearance: none !important;
  box-shadow: none !important;
}

.wext__single-value,
.wext__menu-list {
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.wext__multi-value {
  border-radius: 0.2rem !important;
  background-color: #dbeafe !important;
  color: #0040af !important;
}
.wext__multi-value__label {
  text-transform: capitalize;
  color: #0040af !important;
}

.wext__input:focus {
  box-shadow: none !important;
}

/* React Select Filter */
.wext__control {
  border-radius: 0.375rem !important;
}
.wext-filter__placeholder {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.wext-filter__multi-value__remove {
  border-radius: 0.2rem !important;
}
.wext-filter__single-value {
  font-size: 0.875rem !important;
}

.wext-filter__input-container {
  font-size: 0.875rem !important;
  margin: 1px !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.wext-filter__control {
  border-radius: 0.375rem !important;
}
.wext-filter__control.wext-filter__control--is-focused {
  border: 2px solid #37c5f7 !important;
  appearance: none !important;
  box-shadow: none !important;
}

.wext-filter__single-value,
.wext-filter__menu-list {
  text-transform: capitalize;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.wext-filter__multi-value {
  border-radius: 0.2rem !important;
  background-color: #dbeafe !important;
  color: #0040af !important;
}
.wext-filter__multi-value__label {
  text-transform: capitalize;
  color: #0040af !important;
}

.wext-filter__input:focus {
  box-shadow: none !important;
}
.wext__control--is-disabled {
  background-color: rgb(241 245 249) !important;
}
.wext__single-value--is-disabled {
  color: rgb(100 116 139) !important;
}
.select_campaign .wext__single-value,
.select_campaign .wext__option {
  text-transform: lowercase !important;
}
.select_campaign .wext__menu {
  z-index: 100 !important;
}

.select_option .wext__single-value,
.select_option .wext__option {
  text-transform: capitalize !important;
}
.select_option .wext__menu {
  z-index: 100 !important;
}

.bubble-arrow {
  position: absolute;
  top: 0;
  z-index: 100;
  display: block;
  width: 8px;
  height: 13px;
}

.bubble-arrow.left-arrow {
  left: -8px;
}

.bubble-arrow.right-arrow {
  right: -8px;
}

.custom-option {
  transition: background 60ms;
}
.custom-option:hover {
  transition-delay: 60ms;
  background: #deebff;
}

.button-preview {
  color: #4396e8;
  box-shadow: 1px 2px 10px 8px #d0d0d0;
  padding: 10px;
  border-radius: 6px;
}